@tailwind base;
@tailwind components;
@tailwind utilities;

/* Base styles */
:root {
  --content-width: min(90vw, 1200px);
  --header-height: 60px;
}

/* Global scroll behavior */
html, body {
  overflow: hidden;
  height: 100%;
  overscroll-behavior: none;
  touch-action: none;
  -webkit-tap-highlight-color: transparent;
}

/* Better font rendering */
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @apply text-base md:text-lg;
}

/* Glass effect utility */
.glass {
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

/* Mobile touch optimization */
@media (hover: none) {
  .hover\:scale-105 {
    transform: none !important;
  }

  .h-screen {
    height: 100vh;
    height: -webkit-fill-available;
  }
}

/* For mobile viewport height */
@supports (-webkit-touch-callout: none) {
  .h-screen {
    height: -webkit-fill-available;
  }
}

/* Responsive text utilities */
@layer utilities {
  .text-balance {
    text-wrap: balance;
  }

  .text-pretty {
    text-wrap: pretty;
  }
}

/* Custom animations */
@keyframes float {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

.animate-float {
  animation: float 3s ease-in-out infinite;
}

/* Focus styles for better accessibility */
:focus-visible {
  outline: 2px solid theme('colors.blue.400');
  outline-offset: 2px;
}

/* Prevent text selection on interactive elements */
.no-select {
  -webkit-user-select: none;
  user-select: none;
}

/* Remove any existing snap scroll behavior */
* {
  scroll-snap-type: none;
}

/* Hide scrollbars but keep functionality */
* {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;     /* Firefox */
}

*::-webkit-scrollbar {
  display: none;
}

/* Optimize animations */
.touch-none {
  touch-action: none;
  -webkit-overflow-scrolling: touch;
  transform: translateZ(0);
  backface-visibility: hidden;
  perspective: 1000px;
}